<template>
    <div class="container">
        <div class="row">
            <!-- <Button class="p-button-raised p-button-rounded my-3 p-button-danger" icon="pi pi-arrow-left"
                @click="goBack" /> -->
            <div class="col-12 py-4">
                <DocumentsList :fromTopic="true" />
            </div>
        </div>
    </div>
</template>
<script>
import DocumentsList from './components/DocumentsList.vue';
// import Button from 'primevue/button';

export default {
    name: 'TopicListDocument',
    components: { 
        DocumentsList,
        // Button
    },
    methods:{
        goBack() {
            this.$router.go(-1);
        },
    }

};
</script>
  